<template>
  <div
    class="copyright-area copyright-style-one variation-two"
    style="background-color: #333333"
  >
    <div class="footer_container" style="">
      <div class="footer_container_left">
        <div class="footer_left_text_bold">联系我们</div>
        <div class="footer_left_text_next">联系方式</div>
        <div class="footer_left_text">公司电话：17636573718</div>
        <div class="footer_left_text">服务电话：400 994 0599</div>
        <div class="footer_left_text_next">邮箱地址</div>
        <div class="footer_left_text">master@topowersx.com</div>
        <div class="footer_left_text_next">联系地址</div>
        <div class="footer_left_text">山西省太原市晋源区义井街国投大厦8层</div>
      </div>

      <div class="footer_container_center">
        <div class="footer_container_center_title">核心产品</div>
        <div class="footer_container_center_item">
          <div
            v-for="(item, index) in productList"
            :key="index"
            @click="change(item.websiteProductId)"
            class="footer_container_center_default"
            @mouseenter="changeStyle('enter', $event)"
            @mouseleave="changeStyle('leave', $event)"
          >
            <span>{{ item.websiteProductName }}</span>
          </div>
        </div>
        <div class="footer_center_bottom">
          <div>
            <router-link to="/gallery" style="color: white; font-weight: bold"
              >项目案例</router-link
            >
          </div>
          <div>
            <router-link to="/news" style="color: white; font-weight: bold"
              >新闻资讯</router-link
            >
          </div>
          <div>
            <router-link to="/services" style="color: white; font-weight: bold"
              >支持与服务</router-link
            >
          </div>
          <div>
            <router-link to="/about" style="color: white; font-weight: bold"
              >关于我们</router-link
            >
          </div>
        </div>
      </div>

      <div class="footer_container_right">
        <img
          width="100px"
          src="../../../assets/images/gzh.jpg"
          alt=""
          style="margin: 0 auto"
        />
        <span style="text-align: center; margin-top: 20px; color: white"
          >关注公众号</span
        >
      </div>
    </div>

    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-4 col-md-7 col-sm-12 col-12">
          <div class="copyright-left">
            <!-- <ul class="ft-menu link-hover">
                            <li><router-link to="#">Privacy Policy</router-link></li>
                            <li><router-link to="#">Terms And Condition</router-link></li>
                            <li><router-link to="/contact">Contact Us</router-link></li>
                        </ul> -->
          </div>
        </div>
        <div class="col-lg-4 col-md-5 mt_sm--20">
          <div class="copyright-center text-center">
            <!-- <ul class="social-icon social-default color-lessdark justify-content-center">
                            <li><a href="https://www/facebook.com/" target="_blank"><Icon name="facebook"/></a></li>
                            <li><a href="https://www/twitter.com/" target="_blank"><Icon name="twitter"/></a></li>
                            <li><a href="https://www/instagram.com/" target="_blank"><Icon name="instagram"/></a></li>
                            <li><a href="https://www/linkedin.com/" target="_blank"><Icon name="linkedin"/></a></li>
                        </ul> -->
            <p class="copyright-text">
              <a href="https://beian.miit.gov.cn"
                >ICP备案号 晋ICP备2023018203号</a
              >
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20">
          <div class="copyright-right text-center text-lg-end">
            <p class="copyright-text" style="color: white">
              Copyright All rights reserved ©
              {{ new Date().getFullYear() }} 卓能科技 .
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../../icon/Icon";
import { productListAll } from "@/api/index";

export default {
  name: "CopyrightTwo",
  components: { Icon },

  data() {
    return {
      productList: [],
    };
  },

  methods: {
    getProductListAll() {
      productListAll().then((response) => {
        console.log("response.data");
        this.productList = response.data;
      });
    },
    change(id) {
      this.$router.push({ path: "/detail", query: { id: id } });

      if (this.$route.path === "/detail") {
        location.reload();
      }
    },

    changeStyle(type, e) {
      console.log(type);
      if (type == "enter") {
        e.currentTarget.className = "footer_container_center_enter";
      } else if (type == "leave") {
        e.currentTarget.className = "footer_container_center_leave";
      }
    },
  },
  mounted() {
    this.getProductListAll();
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .footer_container {
    display: flex;
    flex-direction: row;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
  .footer_container_left {
    display: flex;
    flex-direction: column;
    width: 25%;
    font-size: 14px;
    color: black;
  }
  .footer_left_text_bold {
    color: white;
    margin-left: 30px;
    margin-bottom: 1px;
    font-weight: bold;
  }
  .footer_left_text_next {
    color: white;
    margin-left: 30px;
    margin-top: 10px;
  }
  .footer_left_text {
    color: white;
    margin-left: 30px;
  }
  .footer_container_center {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 15px;
    color: black;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }

  /*  */
  .footer_container_center_title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: white;
  }

  .footer_container_center_item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px 50px;
  }

  .footer_container_center_default {
    display: flex;
    flex-direction: row;
    width: 20%;
    line-height: 50px;
    cursor: pointer;
    justify-content: center;
    color: white;
  }

  .footer_container_center_enter {
    display: flex;
    flex-direction: row;
    width: 20%;
    line-height: 50px;
    cursor: pointer;
    justify-content: center;
    color: #0080ff;
  }

  .footer_container_center_leave {
    display: flex;
    flex-direction: row;
    width: 20%;
    line-height: 50px;
    cursor: pointer;
    justify-content: center;
    color: white;
  }

  .footer_center_bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px 100px;
  }

  .footer_container_right {
    display: flex;
    flex-direction: column;
    width: 25%;
    font-size: 14px;
    color: black;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .footer_container {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
  .footer_container_left {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 14px;
    color: black;
    text-align: center;
  }
  .footer_left_text_bold {
    color: white;
    margin-bottom: 1px;
    font-weight: bold;
  }
  .footer_left_text_next {
    color: white;
    margin-top: 10px;
  }
  .footer_left_text {
    color: white;
  }
  .footer_container_center {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 15px;
    color: black;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    margin-top: 50px;
  }

  .footer_container_center_title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: white;
  }

  .footer_container_center_item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px 50px;
  }

  .footer_container_center_default {
    display: flex;
    flex-direction: row;
    width: 50%;
    line-height: 50px;
    cursor: pointer;
    justify-content: center;
    color: white;
  }

  .footer_container_center_enter {
    display: flex;
    flex-direction: row;
    width: 20%;
    line-height: 50px;
    cursor: pointer;
    justify-content: center;
    color: #0080ff;
  }

  .footer_container_center_leave {
    display: flex;
    flex-direction: row;
    width: 20%;
    line-height: 50px;
    cursor: pointer;
    justify-content: center;
    color: white;
  }
  .footer_center_bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px;
  }
  .footer_container_right {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 14px;
    color: black;
    justify-content: center;
  }
}
</style>
